<template>
  <v-dialog
    v-model="data.show"
    :content-class="contentClass"
    :fullscreen="mobile || initializedFullscreen"
    :overlay="false"
    transition="scale-transition"
    max-width="600"
    persistent
  >
    <slot/>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwDialog',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    initializedFullscreen: false,
  }),

  computed: {
    ...mapGetters({
      mobile: 'getMobile',
    }),

    contentClass() {
      if (this.data.show) return 'cw-dialog cw-dialog--active';

      return 'cw-dialog';
    },
  },

  mounted() {
    this.$bus.$on('OPEN_DIALOG', () => {
      setTimeout(() => {
        // Open dialog
        this.data.show = true;
        this.initializedFullscreen = this.mobile;
      }, 100);
    });

    this.$bus.$on('CLOSE_DIALOG', () => {
      // Close dialog
      this.data.show = false;
      this.initializedFullscreen = false;
    });
  },
};
</script>
